import React from 'react'
import styles from './get-in-touch.module.css'
import Container from './container.js'

import { faEnvelope} from "@fortawesome/free-solid-svg-icons"
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function GetInTouch() {
	return(
    <section className={styles.getInTouch}>
    <a id='GetInTouchLink'/>
      <Container>
      	<h2 className={styles.h2}>
      		Get In Touch
      	</h2>
      	<div className={styles.flex}>
      		<div className={styles.contact}>
      			<div className={styles.imagebox}>
              <a href="mailto:aggie@tippconsulting.com">
               <FontAwesomeIcon icon={faEnvelope} />
              </a>
      			</div>
      			<h3 className={styles.site}>
              Send an Email
      			</h3>
      			<p className={styles.address}>
              <a href="mailto:aggie@tippconsulting.com">aggie@tippconsulting.com</a>
      			</p>
      		</div>
      		<div className={styles.contact}>
      			<div className={styles.imagebox}>
              <a href="https://www.linkedin.com/in/aggie-mikulski-51a27010/" target="_blank">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
      			</div>
      			<h3 className={styles.site}>
              LinkedIn
      			</h3>
      			<p className={styles.address}>
              <a href="https://www.linkedin.com/in/aggie-mikulski-51a27010/" target="_blank">/aggie-mikulski</a>
      			</p>
      		</div>
      	</div>
      </Container>
    </section>
	)
}

export default GetInTouch

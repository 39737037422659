import React from 'react'
import styles from './services.module.css'
import Container from './container.js'
import icon1 from './icons/group-23.svg'
import icon2 from './icons/group-25.svg'
import icon3 from './icons/group-26.svg'
import icon4 from './icons/group-24.svg'
import ReadMoreReact from 'read-more-react';

function Services(props) {
	const { help } = props
	return(
		<section className={styles.services}>
			<Container>
			<a id="servicesLink"/>
				<div className={styles.flex}>
					<div className={styles.heading}>
						<h2 className={styles.h2}> {help.title} </h2>
						<p className={styles.headerP}> {help.description}
						</p>
					</div>
					<div className={styles.card}>
						<img role="presentation" alt="teamwork" src={icon1} className = {styles.icon}/>
						<h3 className={styles.h3}> {help.help1}</h3>
						<ReadMoreReact
							text = {help.help1Desc}
							min={150} ideal={160} max={170} readMoreText="Read More"/>
					</div>
					<div className={styles.card}>
						<img role="presentation" alt="business" src={icon2} className = {styles.icon}/>
						<h3 className={styles.h3}>{help.help2}</h3>
						<ReadMoreReact
							text = {help.help2Desc}
							min={110} ideal={120} max={130} readMoreText="Read More"/>
					</div>
					<div className={styles.card}>
						<img role="presentation" alt="relations" src={icon3} className = {styles.icon}/>
						<h3 className={styles.h3}> {help.help3} </h3>
						<ReadMoreReact
							text = {help.help3Desc}
							min={130} ideal={140} max={150} readMoreText="Read More"/>
					</div>
					<div className={styles.card}>
						<img role="presentation" alt="coaching" src={icon4} className = {styles.icon}/>
						<h3 className={styles.h3}>{help.help4}</h3>
						<ReadMoreReact
							text = {help.help4Desc}
							min={130} ideal={140} max={150} readMoreText="Read More"/>
					</div>
				</div>
			</Container>
		</section>
	)
}

export default Services

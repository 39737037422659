import React from 'react'
import styles from './about-company.module.css'
import Container from './container.js'

import BlockContent from './block-content'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

function AboutCompany(props) {
  const { aboutCompany } = props

  return(
    <section className={styles.aboutCompany}>

    	<a id='AboutCompanyLink'/>
      <div className={styles.wrapper}>
      	<div className={styles.heading}>
      		<h2 className={styles.h2}>
            {aboutCompany.title}
      		</h2>
      	</div>
	      <div className={styles.flex}>
	      	<div className={styles.infograph}>
            <img className={styles.graph}
              src={imageUrlFor(buildImageObj(aboutCompany.mainImage))
                .url()}
              alt={aboutCompany.mainImage.alt}
            />
	      	</div>
	      	<div className={styles.info}>
            {aboutCompany._rawDescription && <BlockContent blocks={aboutCompany._rawDescription || []} />}
	      	</div>
	      </div>
      </div>
    </section>
	)
}

export default AboutCompany

import React from 'react'
import styles from './testimonials.module.css'
import Container from './container.js'
import image from './icons/group-meeting.webp'
import brands from './icons/brands/brands3x.png'

import BlockContent from './block-content'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const handleOnDragStart = e => e.preventDefault()

function Testimonials(props) {
  const { aboutMe } = props
  return(
    <section className={styles.testimonials}>
      <Container>
        <h2 className={styles.h2}> What People are Saying </h2>
        <div className={styles.carousel}>
          <AliceCarousel
            mouseDragEnabled
            mouseTrackingEnabled={true}
            buttonsDisabled={true}
            autoPlay={true}
            dotsDisabled={false}
            autoPlayInterval={10000}>

            <div onDragStart={handleOnDragStart} className={styles.item}>
              <p className={styles.copy}>
                In my work with Aggie she has been very effective in developing trust, creating sustainable relationships by seeking to understand and seeing issues from a stakeholders' perspective. She does genuine stakeholder engagement. Aggie is an effective strategist with a dynamic approach. She infuses experience from multiple sectors and varied organizational settings. Her ability to help develop the Health Technology Accelerator capacity within the Alberta eco-system, specifically linking this to the Strategic Clinical Networks, has advanced our innovation approach.
              </p>
              <p className={styles.name}>Tracy Wasylak <span>Chief Program Officer, Strategic Clinical Networks</span> </p>
              <p className={styles.company}>Alberta Health Services</p>
            </div>
            <div onDragStart={handleOnDragStart} className={styles.item}>
              <p className={styles.copy}>
                In my Exec VP Position at TEC Edmonton Aggie reported directly to me. Aggie was a pleasure to work with. She was focused and delivered on all her tasks. As an EVP I could depend on her to build pathways for the TEC Health Accelerator and to rebuild and maintain important relationships. She was strategic, tactical, personable, considerate, passionate and dedicated.
              </p>
              <p className={styles.name}>Randy YATSCOFF<span> Executive Vice President Business Development</span></p>
              <p className={styles.company}>TEC Edmonton</p>
            </div>
            <div onDragStart={handleOnDragStart} className={styles.item}>
              <p className={styles.copy}>
                When it comes to consulting expertise there are lots that talk a good game, but Aggie made things happen. She not only created a plan to help m-Health Solutions navigate the complex environment of Alberta’s healthcare sector, but determinedly and patiently worked to execute it. Aggie is an astute strategist with a warm and attentive approach to stakeholder engagement.
                <br /><br />
                I was ready to give up on Alberta but she enabled me to regain confidence that our patient and physician centric offering has a chance in Alberta. Aggie encouraged, advised, networked, promoted and helped me achieve some milestones. The best part is that I can trust her to represent m-Health Solutions appropriately.
              </p>
              <p className={styles.name}>Sandy Schwenger <span>CEO</span></p>
              <p className={styles.company}>m-Health Solutions & PatientCare Solutions</p>
            </div>
            <div onDragStart={handleOnDragStart} className={styles.item}>
              <p className={styles.copy}>
                Aggie is a results driven self-starter who excels in customer engagement, stakeholder mapping, team development and strategic planning. During her tenure with AstraZeneca Aggie delivered sales results while fostering long standing professional relationships.
              </p>
              <p className={styles.name}>James Kavanagh<span> Regional Business Manager Cardiovascular, Renal and Metabolism</span></p>
              <p className={styles.company}>AstraZeneca</p>
            </div>
          </AliceCarousel>
        </div>
      </Container>
    </section>
  )
}

export default Testimonials

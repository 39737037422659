import React from 'react'
import ReactDOM from 'react-dom'

import { graphql } from 'gatsby'
import styles from './above-the-fold.module.css'
import Container from './container.js'
import kite from './icons/kite.svg'
import portrait from './icons/group.png'

import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


function AboveTheFold (props) {
  const { aboveFold } = props
  return (
    <section className={styles.aboveTheFold}>
      <Container>
        <h1 className={styles.h1}><span className={styles.h1Sub}>
          {aboveFold.name}</span> {aboveFold.title}
          <img role="presentation" alt="kite" src={kite} className = {styles.kite}/>
        </h1>
        <h2 className={styles.h2}> {aboveFold.tagline} </h2>
          <p className={styles.p}>{aboveFold.description}</p>
        <Link to='servicesLink' className={styles.btn} smooth={true} duration={500}>
          How can I help?
        </Link>
      </Container>
      <div className={styles.imagebox}>
          <img role="presentation" alt="founder" src={portrait} className = {styles.portrait}/>
      </div>
    </section>
  )
}

export default AboveTheFold

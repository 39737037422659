import React from 'react'
import ReactDOM from 'react-dom'

import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'

import AboveTheFold from '../components/above-the-fold'
import Services from '../components/services'
import AboutCompany from  '../components/about-company'
import AboutMe from '../components/about-me'
import Testimonials from '../components/testimonials'
import GetInTouch from '../components/get-in-touch'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    aboveFold: sanityAboveFold(_id: { regex: "/(drafts.|)aboveFold/" }) {
      id
      title
      name
      description
      tagline
    }
    help: sanityHelp(_id: { regex: "/(drafts.|)help/" }) {
      id
      title
      description
      help1
      help1Desc
      help2
      help2Desc
      help3
      help3Desc
      help4
      help4Desc
    }
    aboutCompany: sanityAboutCompany(_id: { regex: "/(drafts.|)aboutCompany/" }) {
      id
      title
      _rawDescription
      mainImage {
        asset {
          _id
          url
        }
        alt
      }
    }
    aboutMe: sanityAboutMe(_id: { regex: "/(drafts.|)aboutMe/" }) {
      id
      title
      tagline
      _rawDescription
      mainImage {
        asset {
          _id
          url
        }
        alt
      }
      innerImage {
        asset {
          _id
          url
        }
        alt
      }
      innerHeading
      innerText
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  if (!data.aboveFold) {
    throw new Error(
      'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
    )
  }
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <AboveTheFold aboveFold={data.aboveFold} />
      <Services help={data.help} />
      <AboutCompany aboutCompany={data.aboutCompany} />
      <AboutMe aboutMe={data.aboutMe} />
      <Testimonials />
      <GetInTouch/>
    </Layout>
  )
}

export default IndexPage

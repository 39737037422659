import React from 'react'
import styles from './about-me.module.css'
import Container from './container.js'
import image from './icons/group-meeting.webp'
import brands from './icons/brands/brands3x.png'

import BlockContent from './block-content'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

function AboutMe(props) {
  const { aboutMe } = props
	return(
    <section className={styles.aboutMe}>
      <Container>
        <a id='AboutMeLink'/>
      	<div className={styles.heading}>
      		<h2 className={styles.h2}>
      			{aboutMe.title}
      		</h2>
      	</div>
      	<div className={styles.info1}>
      		<p className={styles.p1}>{aboutMe.tagline}</p>
      		<div className={styles.p2}>
            {aboutMe._rawDescription && <BlockContent blocks={aboutMe._rawDescription || []} />}
      		</div>
      	</div>
      	<div className={styles.brands}>
          <img className={styles.brands}
              src={imageUrlFor(buildImageObj(aboutMe.mainImage))
                .url()}
              alt={aboutMe.mainImage.alt}
            />
      	</div>
      	<div className={styles.flex}>
      		<div className={styles.photobox}>
            <img className={styles.image}
              src={imageUrlFor(buildImageObj(aboutMe.innerImage))
                .url()}
              alt={aboutMe.innerImage.alt}
            />
      		</div>
      		<div className={styles.info2}>
      			<p className={styles.p1}>
              {aboutMe.innerHeading}
      			</p>
      			<p className={styles.p2}>
              {aboutMe.innerText}
      			</p>
      		</div>
      	</div>
      </Container>
    </section>
	)
}

export default AboutMe
